import { lazy } from 'react';
import { FEATURE_FLAGS } from '@pomp-libs/core';

const RoutineWizardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "RoutineWizardPage" */ 'features/routines/routine-wizard/components/routine-wizard-page'
    )
);

// const RoutinesList = lazy(() =>
//   import(
//     /* webpackChunkName: "RoutineListView" */ 'features/routines/components/routine-list-view'
//   )
// );

const RoutineTemplatesListView = lazy(() =>
  import(
    /* webpackChunkName: "RoutineTemplatesView" */ 'features/routines/routine-templates'
  ).then(({ RoutineTemplatesView }) => ({
    default: RoutineTemplatesView,
  }))
);

const PublicRoutineTemplateViewPage = lazy(() =>
  import(
    /* webpackChunkName: "PublicRoutineTemplateView" */ 'features/routines/pages'
  ).then(({ PublicRoutineTemplateView }) => ({
    default: PublicRoutineTemplateView,
  }))
);

export const RoutinesRoutes = [
  {
    component: RoutineTemplatesListView,
    isExact: true,
    key: 'routine-templates',
    path: '/routines/templates',
    flag: FEATURE_FLAGS.MANAGE_ROUTINES,
  },
  // TODO: Remove deprecated route once we're sure we don't need it anymore.
  {
    component: RoutineWizardPage,
    isExact: true,
    key: 'new-routine-deprecated',
    path: '/user/:userId/routines/new',
    flag: FEATURE_FLAGS.MANAGE_ROUTINES,
  },
  {
    component: RoutineWizardPage,
    isExact: true,
    key: 'new-routine',
    path: '/users/:userId/routines/new',
    flag: FEATURE_FLAGS.ASSIGN_ROUTINES,
  },
  {
    component: RoutineWizardPage,
    isExact: true,
    key: 'edit-routine',
    path: '/routines/:routineId',
    flag: FEATURE_FLAGS.MANAGE_ROUTINES,
  },
  {
    component: PublicRoutineTemplateViewPage,
    isExact: true,
    key: 'view-public-routine',
    path: '/routines/public/:routineId',
  },
  // {
  //   component: RoutinesList,
  //   isExact: true,
  //   key: 'list-routines',
  //   path: '/routines',
  //   flag: FEATURE_FLAGS.MANAGE_ROUTINES,
  // },
];
